import React from 'react'
import clsx from 'clsx'

import MCLogoInline from 'componentsNew/icons/mc-logo-inline'

import Button from '../Button'

import styles from './styles.module.scss'

const Footer = ({ phone }) => (
  <footer className={styles.footer}>
    <div className={styles.container}>
      <div className={styles.top}>
        <MCLogoInline />
        <div className={styles.topRight}>
          <a href={`tel:${phone}`} className={clsx('clear-tty', styles.callNow)}>
            {phone}
          </a>
          <Button size="small">Get Free Research Help</Button>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottomLeft}>
          <span className={styles.cc}>&copy; 2021 Medicare Companion</span>
          <span className={styles.txt}>
            A privately owned, non-government website powered by PolicyScout
          </span>
        </div>
        <div className={styles.bottomRight}>
          <a target="_blank" href="/terms-of-service">
            Terms Of Services
          </a>
          <a target="_blank" href="/privacy-policy">
            Privacy Policy
          </a>
          <a target="_blank" href="/ccpa">
            CCPA
          </a>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
